import React from 'react'

const Pricing = () => {
    return(
        <div className="home">
            <h1>Pricing</h1>
        </div>
    )
}

export default Pricing
