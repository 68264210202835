import React, { useState } from 'react'
import './Home.css';
import blueBall from './ball.png';
import banner from './cpmc.gif';
import discord from './d.png';
import instagram from './ig.png';
import linkedin from './li.png';
import threads from './th.png';
import tiktok from './tt.png';
import twitter from './t.png';
import youtube from './yt.png';
// import { useNavigate } from "react-router-dom";
import '../fonts.css'
import { Link } from 'react-router-dom'
import axios from 'axios';

const HomePage = () => {
    const [tiktokUser, setTikTokUser] = useState();
    const [isInProcess, setIsInProcess] = useState(false);
    const [filesUrls, setFilesUrls] = useState([]);

    const processProfileUrl = async (profile_url) => {
        const response = await axios.post(process.env.REACT_APP_API_HOST + '/api/mix-subtitles?profile_url=' + encodeURIComponent(profile_url), null, { timeout: 10000000000 });
        if (response.data.status === false) {
            alert("Account is already in processing, please try again in a few minutes");
            return false;
        }
        
        setFilesUrls(response.data.filter(r => r.status).map(r => r.file_url));
        return true;
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setTikTokUser(value);
    };

    // const navigate = useNavigate();
    const handle_submit = async (e) => {
        e.preventDefault();

        if (isInProcess) {
            alert("Account is already in processing, please wait");
            return;
        }

        setIsInProcess(true)
        await processProfileUrl(tiktokUser)
        setIsInProcess(false)
        // navigate('/edit_scripts', { state: tiktokUser })
    };
    return (
        <>
            <div className="home home_layout">

                <div className="slogan bebas" >
                    <h1 >COPY.</h1>
                    <h1>PASTE.</h1>
                    <h1>MONETIZE.</h1>
                </div>

                <div className="description" >
                    <h3>Clipium is a generative AI that repurposes entire accounts into short form original content. Powered byermermnbemr[e </h3>
                </div>

                <div className="home_search" >
                    <form onSubmit={handle_submit}>
                        <input type="text" name="name" placeholder="Drop a tiktok @" onChange={handleOnChange} />
                        { isInProcess ? <input type="submit" value="Waiting..." /> : <input type="submit" value="Submit" /> }
                    </form>
                </div>

                <div className="videos-container">
                    {filesUrls.map((url, index) => (
                        <div key={index} className="video-item">
                            <video controls src={url} width="300" />
                        </div>
                    ))}
                </div>

                <div className="image" >

                    <img src={blueBall} alt="ball" style={{ width: '45vw' }} />
                </div>

            </div>
            <img src={banner} alt="banner" />

            <div className="footer" >
                <div className="_" >
                </div>
                <div className="company" >
                    <Link to="#">Company</Link>
                </div>
                <div className="coming_soon" >
                    <Link to="#">ComingSoon</Link>
                </div>
                <div className="join" >
                    <Link to="#">Join the Clipium Community</Link>
                </div>
                <div className="clipium" >
                    <Link to="#">Clipium</Link>
                </div>
                <div className="afiliate" >
                    <Link to="#">Become an affiliate</Link>
                </div>
                <div className="socials" >
                    <Link to="#"> <img src={instagram} alt="instagram" style={{ width: '3vw' }} /> </Link>
                    <Link to="#"> <img src={tiktok} alt="tiktok" style={{ width: '3vw' }} /> </Link>
                    <Link to="#"> <img src={threads} alt="threads" style={{ width: '3vw' }} /> </Link>
                    <Link to="#"> <img src={linkedin} alt="twitter" style={{ width: '3vw' }} /> </Link>
                    <Link to="#"> <img src={youtube} alt="youtube" style={{ width: '3vw' }} /> </Link>
                    <Link to="https://twitter.com"> <img src={twitter} alt="twitter" style={{ width: '3vw' }} /> </Link>
                    <Link to="#"> <img src={discord} alt="discord" style={{ width: '3vw' }} /> </Link>
                </div>
                <div className="contact" >
                    <Link to="#">Contact Us</Link>
                </div>
                <div className="template" >
                    <Link to="#">Templates</Link>
                </div>

            </div>

        </>
    )
}

export default HomePage

