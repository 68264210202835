import React from 'react'

const UsagePage = () => {
    return(
        <div className="usage">
            <h1>Usage Page</h1>
        </div>
    )
}

export default UsagePage
