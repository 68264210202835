import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import NavBar from './components/Navbar';
import HomePage from './components/Home';
import SignUpPage from './components/SignUp';
import LoginPage from './components/Login';
import Pricing from './components/Pricing';
import UsagePage from './components/Usage';
import DownloadPage from './components/DownloadPage';
import EditScriptsForm from './components/EditScripts'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
    <NavBar />
    <Routes>
        <Route path="/pricing" element={<Pricing/>}></Route>
        <Route path="/login" element={<LoginPage/>}></Route>
        <Route path="/signup" element={ <SignUpPage/>}> </Route>
        <Route path="/" element= {<HomePage/>}> </Route>
        <Route path="/usage" element= {<UsagePage/>}> </Route>
        <Route path="/edit_scripts" element= {<EditScriptsForm/>}> </Route>
        <Route path="/download" element = {<DownloadPage/>}> </Route>
    </Routes>
    <App />
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
