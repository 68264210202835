import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import './DownloadPage.css';
import loading_vid from './loading2.mp4';

const DownloadPage = () => {
    const location = useLocation();
    const stories = location.state;
    const [downloadLinks, setDownloadLinks] = useState([]);
    const [blobUrls, setBlobUrls] = useState([]);
    const [videosCreated, setVideosCreated] = useState(false);
    useEffect(() => {
        axios.post('/process_videos',stories).then((response) => {
        setDownloadLinks(response.data);
        setVideosCreated(true);
    });
  }, []); 

        useEffect(() => {
    const fetchVideos = async () => {
      const urls = [];
      for (const downloadLink of downloadLinks) {
        try {
          const response = await axios.post('/get_video', { link: downloadLink }, { responseType: 'blob' });
          const blobUrl = URL.createObjectURL(response.data);
          urls.push(blobUrl);
        } catch (error) {
        }
      }
      setBlobUrls(urls);
    };

    if (downloadLinks.length > 0) {
      fetchVideos();
    }
  }, [downloadLinks]);




  const downloadAll = ()=>{
        console.log('downloading all');
        let num = 1;
        blobUrls.forEach((url)=>{
            console.log(url)
            console.log(num);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `video_${num}.mp4`); // Set the filename for the downloaded file
            document.body.appendChild(link);
            link.click();
            link.remove();

            num += 1;
        });



        //const url = window.URL.createObjectURL(new Blob([response.data]));
        //const link = document.createElement('a');
        //link.href = url;
        //link.setAttribute('download', 'vid.mp4'); // Set the filename for the downloaded file
        //document.body.appendChild(link);
        //link.click();
        //link.remove();

  };


    return(
        <>
        {!videosCreated&&(
            <div className="loader">
                <video src={loading_vid} width="800" height="400" autoPlay muted loop />
            </div>

        )}

        <div className="downloads">
            <h1>Download Page</h1>
            <button className="download-all" onClick={downloadAll}>Download All</button>
        </div>


        <div className="videos-container">
            {blobUrls.map((blobUrl, index) => (
                <div key={index} className="video-item">
                    <video controls src={blobUrl} width="300"/>
                </div>
            ))}
        </div>



        </>
    );
};

export default DownloadPage
