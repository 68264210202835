import React, {useState, useEffect} from 'react';
import axios from 'axios';

import {useLocation} from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import './EditScripts.css';
import '../fonts.css';
import loading_vid from './rephrase.mp4';

const Script = ({ script, temp, onDelete}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedScript, setEditedScript] = useState(script);

  const handleScriptClick = () => {
    setIsEditMode(true);
  };

  const handleInputChange = (event) => {
    setEditedScript(event.target.value);
  };

  const handleSaveClick = () => {
    setIsEditMode(false);
  };





  return (
    <div className={`script ${isEditMode ? 'edit-mode' : ''}`}>
      {isEditMode ? (
        <textarea
          value={editedScript}
          onChange={handleInputChange}
          className="script-edit-input"
          rows={10}
          style={{ height: 'auto' }} // Adjust the height and width here
        />
      ) : (
        <div className="script-preview" onClick={handleScriptClick}>
          {script}
        </div>
      )}
      {isEditMode && (
        <>
        <button onClick={handleSaveClick} className="script-save-button">
          Save
        </button>
        <button onClick={onDelete}>
          delete
        </button>
          </>
      )}
    </div>
  );
};

const EditScriptsForm= () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [raw_stories, setRawStories] = useState({});
    const [num_scripts, setNumScripts] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newScript, setNewScript] = useState('');
    const [receivedScripts, setReceivedScripts] = useState(false);

    const handle_script_delete = (key)=>{
        const updated_scripts = {...raw_stories};
        let updated_num_scripts = num_scripts-1;
        delete updated_scripts[key];
        setRawStories(updated_scripts);
        setNumScripts(updated_num_scripts);
    }

    const handlePlusButtonClick = () => {
        console.log('clicked');
        setIsPopupOpen(true); 
    };

    const handleNewInputChange = (event) => {
        setNewScript(event.target.value);
    };

    const handleSaveClick = () =>{
        const updatedScripts = {...raw_stories};
        const key = Date.now().toString();
        updatedScripts[key] = newScript;
        setRawStories(updatedScripts);
        setNumScripts(num_scripts + 1);

        setNewScript('');
        setIsPopupOpen(false);
    };

    const handleCancelClick = () => {
        setNewScript('');
        setIsPopupOpen(false);
    };

    useEffect(() => {
        const get_info = () => {
            axios.post('/scripts', {username: location.state}).then((response) => {
            setRawStories(response.data);
            setNumScripts(response.data.length);
            setNumScripts(Object.keys(response.data).length);
            setReceivedScripts(true);
        });
    };

    get_info();
  }, []); 



    return(
        <div>


        {!receivedScripts&&(
            <div className="loader">
                <video src={loading_vid} width="800" height="400" autoPlay muted loop />
            </div>
        )}




        <div className = "instructions" >
            <h1 className="bebas title">script 2 video</h1>
            <h2 className="inter description">Scripts are left available aswell as you can add scripts by pressing the “plus” button once satisfied press generate to get videos with the Script2Video AI.</h2>
            <button className="continue_gen" onClick={()=>{
                navigate('/download', {state: raw_stories})
            }} >Continue Generation</button>
        </div>




            <div className="scripts-container">
            {
                Object.keys(raw_stories).map((key) => (
                    <Script temp={key} key={key} script={raw_stories[key]} onDelete={()=>handle_script_delete(key)} />
                ))
            }
            </div>

            {(num_scripts< 15 && receivedScripts) && (
                <button className="plus-button continue_gen" onClick={handlePlusButtonClick} >+</button>
            )}

            {isPopupOpen && (
                <div className = "new-script-overlay">
                <div className = "newScriptPopup">
                    <textarea 
                        value={newScript}
                        onChange={handleNewInputChange} 
                        className = "new-script-texarea"
                        rows={10}
                    />
                    <div className="new-script-buttons">
                        <button className="popup-new-save" onClick={handleSaveClick}>
                            save
                        </button>
                        <button className="popup-new-cancel" onClick={handleCancelClick}>
                            cancel
                        </button>
                    </div>
                </div>
                </div>
            )}


        </div>
    )
}

export default EditScriptsForm;
