import React from 'react'

const LoginPage = () => {
    return(
        <div className="home">
            <h1>Login</h1>
        </div>
    )
}

export default LoginPage
