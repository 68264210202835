import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css';
import logo from './Logo.png';
const NavBar = ()=>{
    return (
        <div className=" sticky-top Nav">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark cnv">
         <Link className="navbar-brand" to="/"><img src={logo} alt="Logo" style={{width:'5vw'}}/></Link>
         <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
           <span className="navbar-toggler-icon"></span>
         </button>
         <div className="collapse navbar-collapse" id="navbarNav">
           <ul className="navbar-nav">
             <li className="nav-item active">
               <Link className="nav-link" to="/signup">Sign up<span className="sr-only">(current)</span></Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link" to="/login">Log in</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link" to="/pricing" >Pricing</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link" to="/usage" >How to use</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link disabled" >Template Builder(soon)</Link>
             </li>
             <li className="nav-item">
               <Link className="nav-link disabled" >Become an affiliate(soon)</Link>
             </li>
           </ul>
         </div>
        </nav>
       </div>
    )
}

export default NavBar;
