import React from 'react'

const SignUpPage= () => {
    return(
        <div className="home">
            <h1>Register</h1>
        </div>
    )
}

export default SignUpPage
